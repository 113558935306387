import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.accelerators()
        NavbarComponent.submenu()
        NavbarComponent.lang()
        NavbarComponent.searchFocus()
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        if ($('body.home').length > 0) {
            NavbarComponent.changeLogoColor('banner')
        }

        $(window).on('load scroll', function () {
            const offset = 100 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0 || $('body.home').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $(NavbarEnum.LOGO)
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            $(NavbarEnum.BODY).toggleClass('menu-open')
            if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                NavbarComponent.changeLogoColor('classic')

                if (window.matchMedia('(min-width: 1000px)').matches) {
                    if (!$('.menu__item').hasClass('current')) {
                        $('.menu__item').first().addClass('current')
                        $('.menu__item')
                            .first()
                            .find('.menu__item__submenu')
                            .addClass('submenu-current')
                    }
                }
            } else {
                if (
                    ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) ||
                    $('body.home').length > 0
                ) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }

            if ($(NavbarEnum.BODY).hasClass('submenu-open')) {
                $(NavbarEnum.BODY).removeClass('submenu-open')
                $('.menu__item').removeClass('current')
            }

            //Hide ecomode
            $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
        })

        //Open/Close submenu
        $(NavbarEnum.BURGER_MENU_MORE).on('click', function () {
            $(this).next(NavbarEnum.BURGER_SUBMENU).toggleClass('submenu-open')
        })

        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
        })
    }

    static accelerators() {
        $('.accelerators__item__link').on('click', function (e) {
            const target = $(this).data('target')
            const $menu__item = $(`[data-submenu-key="${target}"`)

            $('[data-trigger="burger-menu-toggle"]').trigger('click')

            $('.menu__wrapper .menu__item').removeClass('current')
            $menu__item.addClass('current')
            $('.menu__item__submenu').removeClass('submenu-current')
            $menu__item.find('.menu__item__submenu').addClass('submenu-current')

            return false
        })
    }

    static submenu() {
        if (window.matchMedia('(min-width: 1000px)').matches) {
            $('.menu__item').mouseenter(function () {
                $('.menu__item__submenu').removeClass('submenu-current')
                $(this).find('.menu__item__submenu').addClass('submenu-current')
                $('.menu__item').removeClass('current')
                $(this).addClass('current')
            })
        } else {
            $('.menu__item > a.menu__item__link, .menu__item .menu__item__more').on(
                'click',
                function () {
                    $('body').addClass('submenu-open')
                    $('.menu__item').removeClass('current')
                    $(this).parent('.menu__item').addClass('current')

                    return false
                },
            )
            $('.menu__item__submenu__heading__prev .arrow').on('click', () => {
                $('body').removeClass('submenu-open')
                $('.menu__item').removeClass('current')

                return false
            })
        }
    }

    static lang() {
        const switcher = '[data-trigger="lang-switcher"]'
        const list = '[data-id="lang-switcher-list"]'

        $(switcher).on('click', () => {
            $(list).toggleClass('lang-switch__list--open')
            setTimeout(() => {
                const currentLangCode = $('html').attr('lang')
                $('.lang-switch__current').html(currentLangCode.substr(0, 2))
            }, '1000')
        })

        // Fix current lang
        setTimeout(() => {
            const currentLangCode = $('html').attr('lang')
            $('.lang-switch__current').html(currentLangCode.substr(0, 2))
        }, '1000')
    }

    static searchFocus() {
        if ($(NavbarEnum.SEARCH_MODAL_INPUT).length > 0) {
            $(NavbarEnum.SEARCH_TRIGGER).on('click', () => {
                document.addEventListener('show.hc.modal', () => {
                    $(NavbarEnum.SEARCH_MODAL_INPUT).focus()
                })
            })
        }
    }
}
