export default class BookingService {
    constructor() {
        BookingService.loadModal()
    }

    static loadModal() {
        $('[data-trigger="booking-modal-link"]').on('click', () => {
            $.ajax({
                url: IRISCollectionTheme.ajaxUrl,
                data: {
                    action: 'get_booking_modal',
                },
                method: 'POST',
            }).done((response) => {
                $('body').append(response)
                $('#modal-customer-booking').removeClass('modal-fancybox--hidden')
                BookingService.closeModal()
                BookingService.submitForm()
            })

            return false
        })
    }

    static closeModal() {
        $('.fancybox__content__close').on('click', () => {
            $('#modal-customer-booking').remove()

            return false
        })
    }

    static submitForm() {
        const $bookingForm = $('#modal-customer-booking .form-reservation')

        $bookingForm.submit((e) => {
            e.preventDefault()

            const values = {}

            $.each($(e.target).serializeArray(), (i, field) => {
                values[field.name] = field.value
            })

            if (values.startdate && values.enddate) {
                window.location.href = `${values.url}/recherche/marketplace/${values.startdate}/${values.enddate}/0/0/`
            }
        })
    }
}
